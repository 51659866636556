import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/retailBanner.jpg';
import referenceVerification from '../../assets/images/referenceVerification.svg';

export const Retail = () => {
    useEffect(() => {
        animate()
      }, [])

  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Industry</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Retail</h1>
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img className='remove-bg' src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
    
     <section id="about" className="ourVisionSec">
       <div className="container">
        <h2 className='main-title text-center'>Retail- finding the best employee fit </h2>
        <div className="para mt-3 text-center mb-4">Retail is the backbone of a company. Our clients need reliable verification as well as accurate to help their businesses grow and mitigate risks. We help our clients with the identification of fake claims and providing correct information. </div>
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={referenceVerification} alt="" />
           </div>
           <div className="col-md-6 ">
                <div className="listContent">
                    <ul>
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Identity verification </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Education verification </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Employment verification </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Reference verification </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Address verification </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Social media verification </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Citizenship verification </li>   
                    </ul>
                </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
