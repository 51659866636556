import { useEffect } from 'react';
import {
  BrowserRouter as Router, Routes, Route, Link, useLocation
} from "react-router-dom";
import { Home } from './pages/home';

import './App.css';
import './assets/css/animate.css';
import { Contact } from './pages/contact';
import { DegreeVerification } from './pages/services/degreeVerification';
import { EmploymentVerification } from './pages/services/employment';
import { ReferenceVerification } from './pages/services/reference';
import { CriminalAndCourtVerification } from './pages/services/criminalAndCourt';
import { CiviLitigation } from './pages/services/civilLitigation';
import { CreditCheck } from './pages/services/creditCheck';
import { LicenseVerification } from './pages/services/licenseVerification';
import { MotorVehicleVerification } from './pages/services/vehicleVerification';
import { SocialMediaVerification } from './pages/services/mediaVerification';
import { GlobalDB } from './pages/services/globalDB';
import { ThirdParty } from './pages/services/thirdParty';
import { Citizenship } from './pages/services/citizenship';
import { DrugTest } from './pages/services/drugtest';
import { TranslationService } from './pages/services/translationService';
import { IDVerification } from './pages/services/idVerification';
import { Aerospace } from './pages/industry/aerospace';
import { HealthCare } from './pages/industry/healthCare';
import { Hospitality } from './pages/industry/hospitality';
import { ManufacturingAndIt } from './pages/industry/IT';
import { NGO } from './pages/industry/NGO';
import { Retail } from './pages/industry/Retail';
import { Staffing } from './pages/industry/staffing';
import { Tenant } from './pages/industry/tenanant';
import { Transportation } from './pages/industry/transportation';
import { SampleReport } from './pages/services/SampleReport';

function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({top:0,behavior:'instant'});
  }, [location.pathname])
  
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/degree-verification" element={<DegreeVerification />} />
          <Route path="/services/employment-verification" element={<EmploymentVerification />} />
          <Route path="/services/reference-verification" element={<ReferenceVerification />} />
          <Route path="/services/criminal-court-verification" element={<CriminalAndCourtVerification />} />
          <Route path="/services/civil-litigation" element={<CiviLitigation />} />
          <Route path="/services/credit-check-verification" element={<CreditCheck />} />
          <Route path="/services/license-verification" element={<LicenseVerification />} />
          <Route path="/services/motor-vehicle-verification" element={<MotorVehicleVerification />} />
          <Route path="/services/social-media-verification" element={<SocialMediaVerification />} />
          <Route path="/services/global-db-sanction" element={<GlobalDB />} />
          <Route path="/services/third-party-diligence" element={<ThirdParty />} />
          {/* <Route path="/services/citizenship-by-investment" element={<Citizenship />} /> */}
          <Route path="/services/drug-test" element={<DrugTest />} />
          <Route path="/services/translation-service" element={<TranslationService />} />
          <Route path="/services/id-verification" element={<IDVerification />} />

          {/* <Route path="/industry/aerospace-defence" element={<Aerospace />} /> */}
          <Route path="/industry/healthcare" element={<HealthCare />} />
          <Route path="/industry/hospitality" element={<Hospitality />} />
          <Route path="/industry/IT" element={<ManufacturingAndIt />} />
          <Route path="/industry/NGO" element={<NGO />} />
          <Route path="/industry/retail" element={<Retail />} />
          <Route path="/industry/staffing" element={<Staffing />} />
          <Route path="/industry/tenant" element={<Tenant />} />
          <Route path="/industry/transportation" element={<Transportation />} />
          {/* <Route path="/resource" element={<SampleReport />} /> */}
        </Routes>
    </div>
  );
}

export default App;
