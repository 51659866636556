import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/creditCheckBanner.svg';
import pastCredibility from '../../assets/images/pastCredibility.jpg';
import creditAuth from '../../assets/images/creditAuth.svg';
import creditPartners from '../../assets/images/creditPartners.svg';

export const CreditCheck = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Credit Check verification </h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={pastCredibility} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Past credibility discovers patterns for future </h2>
               <p className="para mt-4">
               The process of conducting a credit check serves as a pivotal tool in uncovering an individual's past financial credibility, offering invaluable insights into their financial behavior and patterns.               </p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
                <h2 className="main-title"> Credit authenticity is necessary </h2>
                <p className="para mt-4">
                Credit check verification is necessary for sensitive matters involving money and its related transactions. Credit check also determines credibility, which is necessary for companies and employers.  
                </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={creditAuth} alt="" />
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={creditPartners} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Intent screen partners</h2>
               <p className="para mt-4">
               Intent has partnered with different institutions that hold and maintain credit history. We provide a platform for companies and employers to avail of this verification service. This helps our client with credible information when it comes to contracts that involve money by providing credit checks on required companies or personnel.  
                              </p>
             </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
