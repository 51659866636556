import React, { useEffect } from 'react'
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import aboutImg from '../../assets/images/aboutImg.svg';
import mainBanner from '../../assets/images/mainBanner.png';
import crimefreeWorkspace from '../../assets/images/crimefreeWorkspace.jpg';
import criminalVerificationPlat from '../../assets/images/criminalVerificationPlat.svg';
import courtRules from '../../assets/images/courtRules.svg';
import courtVerification from '../../assets/images/courtVerification.svg';

export const CriminalAndCourtVerification = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Criminal and court verification </h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={courtVerification} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={crimefreeWorkspace} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Criminal free workspace</h2>
               <p className="para mt-4">
               In a world where crime persists, ensuring safety and security is paramount. To safeguard against potential risks, thorough criminal background checks are essential for screening prospective employees. At Intent Screen, we uphold stringent standards in verifying criminal histories, prioritizing authenticity and reliability. Our commitment to clients is unwavering, delivering trustworthy assessments to mitigate any concerns regarding criminal backgrounds.
               </p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
                <h2 className="main-title">Approach to Criminal Verification by Intent Screen</h2>
                <p className="para mt-4">
                    The Intent Screen uses different APIs and services that can authenticate a profile for criminal verification. We, through this service, help verify the profile and employee’s claim. The Intent Screen also aims to increase this service by discovering the means to obtain and incorporate non-digitalized criminal records. 
                </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={criminalVerificationPlat} alt="" />
           </div>
         </div>
       </div>
     </section>

     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={courtRules} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Court rules and regulations  </h2>
               <p className="para mt-4">
                    Every country has its judicial structure. Different specialized courts are also available for special cases. Intent screen tends to do a court check on the potential employees to get the go-ahead certificate for employers. Intent Screen has developed a service to help employers authenticate whether the potential client is listed in any of the courts.  
                </p>
             </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
