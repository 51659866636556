import React, { useEffect } from 'react'
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/drugBanner.svg';
import drugVerify from '../../assets/images/drugVerify.png';
import drugPartnerssLabs from '../../assets/images/drugPartnerssLabs.svg';
import drugDoorstep from '../../assets/images/drugDoorstep.svg';

export const DrugTest = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Drug test  screening</h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img className='my-4' src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={drugVerify} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Verify to trust </h2>
               <p className="para mt-4">
               It is common to take some time in any drug and health checks. Even the chances of contamination during the testing and lower accuracy question the reliability. Intent Screen has strict policies and standards when it comes to selecting its testing lab partners. 
                </p>
             </div>
           </div>
         </div>
       </div>
     </section>
 
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Partners with certified labs</h2>
               <p className="para mt-4">
               Developing a drug-free workspace is important for the safety of employees and improves productivity. It defines the overall health of the employees in the company. Intent Screen takes drug test screening very seriously. We have partnered with certified and trusted labs to provide maximum accuracy for drug screening.
                </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={drugPartnerssLabs} alt="" />
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={drugDoorstep} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Drug Testing Procedures</h2>
               {/* <h2 className="main-title">Testing at your doorsteps</h2> */}
               <p className="para mt-4">
               Our partners are government-certified and equipped with the latest and best testing machines. These labs collect the samples at your doorsteps. The reports generated are signed by board-certified doctors and medical practitioners.                 
               </p>
           
             </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
