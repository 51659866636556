import React, { useEffect } from 'react'
import mainBanner from '../../assets/images/thirdPartyBanner.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import thirdParty from '../../assets/images/thirdParty.svg';
import riskMitigation from '../../assets/images/riskMitigation.svg';

export const ThirdParty = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Third-party due diligence</h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={thirdParty} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Third-party due diligence</h2>
               <p className="para mt-4">
               Due diligence is an ongoing process, even if the partnership is still active. Intent Screen tends to provide third-party due diligence to its clients to help them review and monitor third-party for potential risk.                 </p>
             </div>
           </div>
         </div>
       </div>
     </section>
 
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Risk mitigation </h2>
               <p className="para mt-4">
               It helps our clients with potential fraud prevention and risk mitigation. It is an essential service which we provide to our clients to ensure the screening process is successful.                 
               </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={riskMitigation} alt="" />
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
