import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/civilLitigationBanner.svg';
import civilCode from '../../assets/images/civilCode.svg';
import civilNonDigital from '../../assets/images/civilNonDigital.svg';

export const CiviLitigation = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Civil Litigation </h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={civilCode} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Civil code violators damage the company's reputation </h2>
               <p className="para mt-4">
               Companies are always looking for clean employees with the right potential to be the right fit. Civil code violators are a threat to a company’s reputations and growth. Intent Screen helps clients to identify the civil breach that will have an impact on the company.                </p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
                <h2 className="main-title"> Non-digital records are a serious problem </h2>
                <p className="para mt-4">
                We help our clients provide accurate and precise information regarding digitally recorded civil violations information. This helps our clients inform beforehand regarding the potential problems and settlements. We do this by thorough understanding of rules and regulations on both local and global levels.                   </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={civilNonDigital} alt="" />
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
