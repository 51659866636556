import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/transpotBanner.jpg';
import transportation from '../../assets/images/transportation.jpg';

export const Transportation = () => {
    useEffect(() => {
        animate()
      }, [])

  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Industry</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Transportation</h1>
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img className='remove-bg' src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
    
     <section id="about" className="ourVisionSec">
       <div className="container">
        <h2 className='main-title text-center'>Transportation- protection on the road</h2>
        <div className="para mt-3 text-center mb-4">The transportation industry has standard regulations. The main issue of the transportation industry is to have both regulated and non-regulated employees. Intent Screen offers different screening solutions for the transportation industry. </div>
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={transportation} alt="" />
           </div>
           <div className="col-md-6 ">
                <div className="listContent">
                    <ul>
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Motor vehicle verification   </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Professional license verification   </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Global database and Physical address verification   </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Citizenship verification   </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Drug test screening   </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Compliance verification   </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Employment verification   </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Education verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Criminal and court verification    </li>   
                   </ul>
                </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
