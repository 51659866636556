import React from 'react'
import { useEffect } from 'react';

import bannerImg from '../assets/images/flow.gif';
import aboutImg from '../assets/images/aboutImg.svg';
// import ecoIcon from '../assets/images/ecoIcon.png';
import dropShipIco from '../assets/images/dropShipping.png';
import sandicoint from '../assets/images/sandyCoin.png';
import dfi from '../assets/images/Dfi.png';
import mobile from '../assets/images/mobile.png';
import nft from '../assets/images/nft.png';
import dubaiChamber from '../assets/images/dubaiChamber.webp';
import arrowLeft from '../assets/images/post-arrow-Left.svg';
import mainBanner from '../assets/images/mainBanner.png';
import animate from '../assets/js/animate';
import { Navbar } from '../component/navbar';
import { Footer } from '../component/footer';
import { useNavigate } from 'react-router-dom';
import coverVideo from "../assets/video/bacnnerVideo.mp4"
import { Link } from 'react-router-dom';

export const Home = () => {
    const navigate = useNavigate()

      useEffect(() => {
        animate()
      }, [])
  return (
    <div className="homePage">
    <header className="header homePage" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
          <video autoPlay={true} muted="true" loop={true} playsInline={true} poster={""} >
                        <source src={coverVideo} type="video/mp4" />
                        {/* <source src={coverVideowebm} type="video/webm" />
                        <source src={coverVideomov} type="video/mov" /> */}
                        Your browser does not support the video tag.
                    </video>
            <div className="container">

               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Screening Simplified, Trust Amplified.</h1>
               </div>
               <div className='animatable slideInUp animationDelay500' >
                 <p className="para">Cost-effective background screening solutions. Fastest turnaround time in the industry. Precise results. That’s Intent Screen for you</p>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link className="getStatedBtn" to="/contact" >Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             {/* <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div> */}
         </div>
       </section>
     </header>

     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={aboutImg} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               {/* <p className="global">
                 global screening firm
               </p> */}
               <h2 className="main-title">Why Intent Screen</h2>
               {/* <p className="para"><span className="font-bold">Our long-term vision and mission:</span></p> */}
               <p className="para mt-4">Intent Screen is a company that specializes in providing customized solutions to automate complex risk mitigation processes and enhance organizational productivity. We are committed to offer enhances services and be focused on delivering tailored packages with accurate information to meet the specific needs of our clients. We are dedicated to personalized service and effective problem-solving.</p>
               {/* <button className="getBtn mt-4">get started</button> */}
             </div>
           </div>
         </div>
       </div>
     </section>

     <section id="services" className="ecoSystem">
       <div className="container">
         <div className="text-center mb-5 slideInUp ">
           {/* <img className="icon" src={ecoIcon} alt="ecosystem Icon" /> */}
           <h2>Services</h2>
           <p className="para">Next Generation Screening</p>
         </div>
         <div className="row jc-center">
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay100" >
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/degree-verification")}> 
               <img className="logo mb-3" src={sandicoint} alt="drop Shipping" />
               <h5 className="mb-3">Degree Verification</h5>
               <p className="mb-0">To verify document which relate to student</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay100" >
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/employment-verification")}>
               <img className="logo mb-3" src={dfi} alt="drop Shipping" />
               <h5 className="mb-3">Employment</h5>
               <p className="mb-0">Past Employment Verification</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay200">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/reference-verification")}>
               <img className="logo mb-3" src={dropShipIco} alt="drop Shipping" />
               <h5 className="mb-3">Reference Verification</h5>
               <p className="mb-0">Background checks for reference verification</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay300">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/criminal-court-verification")} >
               <img className="mobilelogo " src={mobile} alt="drop Shipping" />
               <h5 className="mb-3">Criminal and Court Verification</h5>
               <p className="mb-0">Criminal activity verification</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay400">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/id-verification")}>
               <img className="mobilelogo " src={mobile} alt="drop Shipping" />
               <h5 className="mb-3">ID Verification</h5>
               <p className="mb-0">Identity verification from relevent authority</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay500">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/civil-litigation")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Civil Litigation</h5>
               <p>Resolving a Legal Dispute </p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay100" >
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/credit-check-verification")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Credit Check Verification</h5>
               <p>Credit History and Credit Default Check</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay100" >
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/license-verification")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">License Verification</h5>
               <p>Professional License Verification</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay200">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/motor-vehicle-verification")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Motor Vehicle record</h5>
               <p>Providing MVR services globally</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay300">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/social-media-verification")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Social Media Verification</h5>
               <p>Social Media Verification</p>
             </div>
           </div>
           {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay400">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/social-media-verification")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">DB & PA Verification</h5>
               <p>Database & physical address verification</p>
             </div>
           </div> */}
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay500">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/global-db-sanction")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Global DB and Sanctions </h5>
               <p>Global database and sanctions checks</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY " >
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/third-party-diligence")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Third-party Due diligence</h5>
               <p>Third-party Due diligence</p>
             </div>
           </div>
           {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay100">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/citizenship-by-investment")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Citizenship by Investment</h5>
               <p>Citizenship by investment</p>
             </div>
           </div> */}
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay200">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/drug-test")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Drug Test Screening</h5>
               <p>Drug test screening</p>
             </div>
           </div>
           <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay300">
             <div className="box hoverTranslateY" onClick={()=>navigate("/services/translation-service")}>
               <img className="logo mb-3" src={nft} alt="drop Shipping" />
               <h5 className="mb-3">Translation Services</h5>
               <p>Language translation services</p>
             </div>
           </div>
         </div>
         {/* <div className="text-center mt-4">
           <button className="getStart">More Detail</button>
         </div> */}
       </div>
     </section>

     <section id="roadmap" className="roadmap overflow_hidden">
       <div className="container">
         <div className="text-center mb-5 animatable slideInUp">
           {/* <img className="icon" src={logoIco} alt="LogoIcon" /> */}
           <p className="project">Intent Screen</p>
           <h2 className="main-title">Understanding & Accessibility</h2>
           <p className="para">Next Generation Screening</p>
         </div>
         {/* {/* roadmap row 1 * /} */}
         <div className="row ai-center roadmapRow ">
           <div className="col-md-5 col-sm-9 order-sm-1 order-2 animatable slideInRight">
             <div className="roadmapBox">
               <h5>Bringing The World To You</h5>
               <p className="para mb-0">Figuratively and metaphorically! We bring the world to your screens.</p>
               <img className="arrow-roadLeft1" src={arrowLeft} alt="" />
             </div>
           </div>
           <div className="col-md-2 col-sm-3 order-sm-2 order-1">
             <div className="roadMapCircleDiv roadMapCircleMarginOdd">
               <h5>1</h5>
               {/* <span>2021</span> */}
             </div>
           </div>
         </div>
         {/* {/* roadmap row 2 * /} */}
         <div className="row ai-center roadmapRow">
           <div className="col-5 d-none-md" />
           <div className="col-md-2 col-sm-3 ">
             <div className="roadMapCircleDiv1 roadMapCircleMarginEven">
               <h5>2</h5>
               {/* <span>2021</span> */}
             </div>
           </div>
           <div className="col-md-5 col-sm-9 animatable slideInLeft">
             <div className="roadmapBox">
               <h5>Compliance & Services</h5>
               <p className="para mb-0">We support our claims with compliance in every facet of the service we provide.</p>
               <img className="arrow-roadRight1" src={arrowLeft} alt="" />
             </div>
           </div>
         </div>
         {/* {/* roadmap row 3 * /} */}
         <div className="row ai-center roadmapRow ">
           <div className="col-md-5 col-sm-9 order-sm-1 order-2 animatable slideInRight">
             <div className="roadmapBox">
               <h5>Candidate Experience Amplified</h5>
               <p className="para mb-0">The most valuable asset of your company needs to feel comfortable while you run a screening test on them.</p>
               <img className="arrow-roadLeft2" src={arrowLeft} alt="" />
             </div>
           </div>
           <div className="col-md-2 col-sm-3 order-sm-2 order-1">
             <div className="roadMapCircleDiv2 roadMapCircleMarginOdd">
               <h5>3</h5>
               {/* <span>2021</span> */}
             </div>
           </div>
         </div>
         {/* {/* roadmap row 4 * /} */}
         <div className="row ai-center roadmapRow">
           <div className="col-5 d-none-md" />
           <div className=" col-md-2 col-sm-3 ">
             <div className="roadMapCircleDiv3 roadMapCircleMarginEven">
               <h5>4</h5>
               {/* <span>2022</span> */}
             </div>
           </div>
           <div className="col-md-5 col-sm-9 animatable slideInLeft">
             <div className="roadmapBox">
               <h5>The Rich Industries Portfolio</h5>
               <p className="para mb-0">We wear multiple hats to fit the needs of every industry. 26, when we last counted.</p>
               <img className="arrow-roadRight2" src={arrowLeft} alt="" />
             </div>
           </div>
         </div>
     
         {/* {/* roadmap row 7 * /} */}
         <div className="row ai-center roadmapRow ">
           <div className="col-md-5 col-sm-9 order-sm-1 order-2 animatable slideInRight">
             <div className="roadmapBox">
               <h5>Technological & Human Resources</h5>
               <p className="para mb-0">Everything we do is based on two simple foundations—provide the highest quality service in shortest possible turn-around time to every one of our business partners. </p>
               <img className="arrow-roadLeft4" src={arrowLeft} alt="" />                
             </div>
           </div>
           <div className="col-md-2 col-sm-3 order-sm-2 order-1">
             <div className="roadMapCircleDivLast last-circle roadMapCircleMarginOdd">
               <h5>5</h5>
               {/* <span>2022</span> */}
             </div>
           </div>
         </div>
       </div>
     </section>

     <section id="services" className="colobsSec">
       <div className="container">
         <div className="text-center mb-5 slideInUp ">
           {/* <img className="icon" src={ecoIcon} alt="ecosystem Icon" /> */}
           <h2>Endorsed by Leading Industry Associations.</h2>
           {/* <p className="para">Next Generation Screening</p> */}
         </div>
         <div className="row jc-center">
          
           <div className="col-lg-3 col-md-4 col-sm-6  mb-4 animatable flipInY animationDelay500">
             <div className="box hoverTranslateY" >
              <a className='dib' href="https://www.dubaichamber.com/" target="_blank" rel="noopener noreferrer">
               <img className="logo " src={dubaiChamber} alt="dubai chamber of commerce" />
              </a>
               {/* <h5 className="mb-3">Civil Litigation</h5> */}
             </div>
           </div>
        
         </div>
       </div>
     </section>


     {/* faq */}

     <section className="sec8" id="faq">
       <div className="container">
         <div className="row ai-center">
           
           <div className="col-lg-5">
             <h1 className="main-title animatable slideInUp" >Most Frequently Asked Questions</h1>
             <p className="para animatable slideInUp animationDelay100" >
             I have a few questions about Intent Screen. Can you answer them for me? You're in luck! We've put together this helpful guide to answer the most frequently asked ones, so read on…
             </p>
           </div>  

           <div className="col-lg-7" >
             
             {/* <div>
               <img className="rectangle" src={rectangle} />
             </div>

             <div className="siediv1">
               <div><h3 className="mosmall">What is Intent Screen?</h3></div>
               <div><h1 className="posmall">+</h1></div>

             </div>
           
             <div className="siediv2">
               <div><h3 className="mosmall">What is payment gateway?</h3>
               <p className="psmall">Payment gateway is the front-end technology that reads payment cards and sends customer information to the merchant acquiring bank for processing.</p>
               </div>
               
               <div><h1 className="posmall">|</h1></div>

             </div>

             <div className="siediv3">
               <div><h3 className="mosmall">Wh0 is Luff's father?</h3></div>
               <div><h1 className="posmall">+</h1></div>

             </div> */}

               <div className="faqMain" id="accordion">
                 <div className="bgEffect"></div>
                 <div className="cardMain">

                   <div className="card">
                     <div className="card-header" id="headingOne">
                       <h5 className="mb-0">
                         <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                         Is the Intent Screen legit?
                           {/* <span className="plus">+</span>
                           <span className="minus">-</span> */}
                         </button>
                       </h5>
                     </div>
                     <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                       <div className="card-body">
                         Intent Screen is a company that specializes in providing customized solutions to automate complex risk mitigation processes and enhance organizational productivity. We are committed to offer enhances services and be focused on delivering tailored packages with accurate information to meet the specific needs of our clients. We are dedicated to personalized service and effective problem-solving.                        </div>
                     </div>
                   </div>

                   <div className="card">
                     <div className="card-header" id="headingTwo">
                       <h5 className="mb-0">
                         <button className="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                         Are the results reliable? 
                           {/* <span className="plus">+</span>
                           <span className="minus">-</span> */}
                         </button>
                       </h5>
                     </div>
                     <div id="collapseTwo" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                       <div className="card-body">
                         Intent Screen’s screening processes aimed at ensuring the right individuals are placed in appropriate roles within organizations. By emphasizing the importance of screening, we highlight its significance in bolstering organizational success. Moreover, the role of screening in instilling confidence in personnel while ensuring compliance with legal and regulatory requirements. This indicates a focus on not only optimizing workforce composition but also maintaining adherence to pertinent standards and regulations.
                       </div>
                     </div>
                   </div>

                   <div className="card">
                     <div className="card-header" id="headingThree">
                       <h5 className="mb-0">
                         <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                         Can the criminal verification be done? 
                           {/* <span>+</span> */}
                         </button>
                       </h5>
                     </div>
                     <div id="collapseThree" className="collapse " aria-labelledby="headingThree" data-parent="#accordion">
                       <div className="card-body">
                       We can provide criminal verification in several regions and as per the scope of the client. 
                       </div>
                     </div>
                   </div>

                   <div className="card">
                     <div className="card-header" id="headingThree">
                       <h5 className="mb-0">
                         <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                         How do I sign up for the Intent Screen? 
                           {/* <span>+</span> */}
                         </button>
                       </h5>
                     </div>
                     <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                       <div className="card-body">
                         Yes, you can contact via email <a href="mailto:sales@intentscreen.com">sales@intentscreen.com</a>
                       </div>
                     </div>
                   </div>

            
                 

                 </div>
               </div>

           </div>

         </div>
       </div>
     </section>

         {/* footer start */}
         <Footer />

    </div>
  )
}
