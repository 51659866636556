import React from 'react'
import logoWhite from '../assets/images/logoWhite.svg';
import arrowUp from '../assets/images/arrowUp.svg';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const Footer = () => {
    function scrollToTop() {
        window.scrollTo(0, 0);
      }
  return (
    <div className="footer">
           <div className="arrowUp" onClick={()=>scrollToTop()}>
             <img src={arrowUp} alt="" />
           </div>
               <div className="container-fluid bg-dark1 text-secondary mt-5 ">
                 <div className='fMain container'>
                     <div className="row gx-5 mx-xs-0">
                         <div className="col-lg-3 col-md-12 pt-5 mb-5">
                           <div className="fLogo mb-4 themeColor font-bold">
                               <img src={logoWhite} alt="" />
                               {/* LOGO */}
                           </div>
                           <div className="d-flex mb-2">
                               <i className="bi bi-geo-alt text-primary mr-2" />
                               <div>
                                <p className="mb-0">Dubai, UAE</p>
                                <p className="mb-0">P.O Box no : 32636</p>
                               </div>
                           </div>
                           <div className="d-flex mb-2">
                               <i className="bi bi-envelope-open text-primary mr-2" />
                               <a className="mb-0" href="mailto:info@intentscreen.com">info@intentscreen.com</a>
                           </div>
                           <div className="d-flex mb-2">
                               <i className="bi bi-telephone text-primary mr-2" />
                               <a className="mb-0" href="tel:+971 558160889">+971 558160889</a>
                           </div>
                           <div className="d-flex mt-4">
                               <a className="btn btn-primary btn-square rounded-circle mr-2" href="#"><i className="fab fa-twitter" /></a>
                               <a className="btn btn-primary btn-square rounded-circle mr-2" href="#"><i className="fab fa-facebook-f" /></a>
                               <a className="btn btn-primary btn-square rounded-circle mr-2" href="#"><i className="fab fa-linkedin-in" /></a>
                               <a className="btn btn-primary btn-square rounded-circle" href="#"><i className="fab fa-instagram" /></a>
                         </div>
                         </div>
                         <div className="col-xl-2 col-lg-3 col-md-12 pt-0 pt-lg-5 mb-5 ml-auto">
                           <h4 className="text-uppercase text-light mb-4">Quick Links</h4>
                           <div className="d-flex flex-column justify-content-start">
                               <Link className="text-secondry mb-2" to="/"><i className="bi bi-arrow-right text-primary mr-2" />Home</Link>
                               <HashLink className="text-secondry mb-2" to="/#services"><i className="bi bi-arrow-right text-primary mr-2" />Service</HashLink>
                               {/* <Link className="text-secondry mb-2" to="/resource"><i className="bi bi-arrow-right text-primary mr-2" />Resource</Link> */}
                               <HashLink className="text-secondry mb-2" to="/#about"><i className="bi bi-arrow-right text-primary mr-2" />About</HashLink>
                           </div>
                         </div>
                         <div className="col-xl-3 col-lg-3 col-md-12 pt-0 pt-lg-5 mb-5">
                           <h4 className="text-uppercase text-light mb-4">Popular Links</h4>
                           <div className="d-flex flex-column justify-content-start">
                               <HashLink className="text-secondry mb-2" to="/"><i className="bi bi-arrow-right text-primary mr-2" />Home</HashLink>
                               <HashLink className="text-secondry mb-2" to="/#about"><i className="bi bi-arrow-right text-primary mr-2" />About</HashLink>
                               <HashLink className="text-secondry" to="/contact"><i className="bi bi-arrow-right text-primary mr-2" />Contact Us</HashLink>
                           </div>
                         </div>
                     </div>
                 </div>
                 
               </div>
               <div className="container py-4 py-lg-0 " style={{background: '#111111'}}>
                   <div className="py-lg-4 text-center rightReserveRow">
                       <p className="text-secondary mb-0">© <a className="text-light fw-bold" href="#">Intent-Screen</a>. All Rights Reserved.</p>
                       {/* <p className="text-secondary mb-0">Powered by <a className="text-light fw-bold" href="https://theextremesolutions.com/" target="_blank">The Extreme Solutions</a></p> */}
                   </div>
               </div>
           </div>
  )
}
