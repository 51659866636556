import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import faqElement from "../assets/images/faqElement.png"
import { Navbar } from "../component/navbar";
import { Footer } from "../component/footer";
import axios from "axios";

export function Contact() {
    
    const [formVal,setFormVal] = useState({
        name:"",
        email:"",
        phone:"",
        subject:"",
        message:""
    })
      // notification setting
      const notify = (response,types) => 
      types(response, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleChange =(e) =>{
        setFormVal({...formVal,[e.target.name]:e.target.value})
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()
        console.log('====================================');
        console.log(formVal);
        console.log('====================================');
        var valid = /^!*(\d!*){10,}$/;
        var validM = valid.test(formVal.phone)
        
        if(formVal.name.length < 3) return notify("Username must be greater than 3 character",toast.error)
        if(formVal.name.length > 30) return notify("Username should be less than 30 character",toast.error);
        if(formVal.phone.includes(' ')) return notify("Spaces are not allowed in phone number",toast.error);
        if(!validM || formVal.phone.length < 10) return notify("Phone must contain atleast 10 digits",toast.error);
        
        console.log(formVal);
        const res = await axios.post("http://localhost:4000/sendmail",formVal)
        console.log(res);
        if(res.data.success){
            notify("Thanks to submit, we will connect as soon as posible.",toast.success)
            setFormVal({
                name:"",
                email:"",
                phone:"",
                subject:"",
                message:""
            })
        }else{
            notify("Something went wrong.",toast.error)
        }
                
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <div className="contactPage">
            <div className="container">
                <Navbar />
            </div>
           
            <section className="getTouchSec">
                <div className="faqElement">
                    <img className="img-fluid" src={faqElement} alt="faq" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h1 className="heading mb-4">Contact <span className="main-title">Us</span></h1>
                            
                        </div>
                        <div className="col-lg-8">
                            <form onSubmit={(e)=>handleSubmit(e)}>
                                <p className="text22 mb-4">Thank you for reaching out to the expert screening team at Intent Screen. Please use our contact form or other listed methods for any questions, quotes, or communication needs with our working professionals. We will do our best to respond as quickly as possible.</p>
                                <div className="d-flex mb-4">
                                    <input onChange={(e)=>handleChange(e)} name="name" value={formVal.name} required  className="form-control me-3" type="text" placeholder="Name" />
                                    <input onChange={(e)=>handleChange(e)} name="email" value={formVal.email} required className="form-control" type="email" placeholder="Email" />
                                </div>
                                <div className="d-flex mb-4">
                                    <input onChange={(e)=>handleChange(e)} name="phone" value={formVal.phone} required className="form-control me-3" type="text" placeholder="Phone" />
                                    <input onChange={(e)=>handleChange(e)} name="subject" value={formVal.subject} required className="form-control" type="text" placeholder="Subject" />
                                </div>
                                <div className="">
                                    <textarea onChange={(e)=>handleChange(e)} name="message" value={formVal.message} required className="form-control"  placeholder="Additional Details" cols="10" rows="5"></textarea>
                                </div>
                                <button type="submit" className="themeBtn mt-5">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
         
             <Footer />
            <ToastContainer pauseOnFocusLoss={false} style={{ width: "max-content" }}/>      
        </div>
    )
}


