import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import degreeVerification from '../../assets/images/degreeVerification.svg';
import degreeVerifyBasic from '../../assets/images/degreeVerifyBasic.svg';
import degreeTrusted from '../../assets/images/degreeTrusted.svg';
import degreeAuthentic from '../../assets/images/degreeAuthentic.svg';

export const DegreeVerification = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Degree/ Education Verification</h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={degreeVerification} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>

     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={degreeVerifyBasic} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Verify the basic truth </h2>
               <p className="para mt-4">A degree or an education is the basic entry criteria in most of the companies. Fake degrees are common throughout the world. Making it question the credibility of employees. Intent Screen verifies the educational degrees of employees for its clients, ensuring accurate credential validation.</p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Trusted information every time expeditiously </h2>
               <p className="para mt-4">We, Intent Screen, have been successful in establishing a secure channel to verify degrees and provide education history. Our streamlined process ensure swift and accurate verification.  </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={degreeTrusted} alt="" />
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={degreeAuthentic} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Authentic and comprehensive reports</h2>
               <p className="para mt-4">We offer standardized education verification and provide concise reports tailored to the client's needs.</p>
             </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
