import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/referenceVerificationBanner.svg';
import referenceVerification from '../../assets/images/referenceVerification.svg';

export const ReferenceVerification = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
      <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Reference verification </h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
      </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={referenceVerification} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Reference Verification for ease of hiring</h2>
               <p className="para mt-4">
               The reference check is an integral part of the hiring process. It helps the employers to get to know more about the potential candidate’s history. We, Intent Screen, provide the services to employers by contacting and verifying the previous employers. This helps our client to verify the credibility of provided references as well as get the reviews for the candidate. This is a critical tool for helping in informed decision-making.                </p>
             </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
