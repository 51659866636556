import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/itBanner.png';
import itManufacture from '../../assets/images/itManufacture.png';

export const ManufacturingAndIt = () => {
    useEffect(() => {
        animate()
      }, [])

  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Industry</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Manufacturing and IT</h1>
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
        <h2 className='main-title text-center'>Manufacturing and IT- screening for developing businesses and technologies </h2>
        <div className="para mt-3 text-center mb-4">Businesses and technologies are ever-developing. They are always in dire need to have the right fit for the right job. IT industry faces a lot of challenges including confidentiality, data theft, etc… Intent screens help them by providing reliable verification to help employers with the shortlisting stage. Additionally, it is expected to experience an urge in the manufacturing job in the coming years. This will require intense screening to get the right fit for the right job.   </div>
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={itManufacture} alt="" />
           </div>
           <div className="col-md-6 ">
                <div className="listContent">
                    <ul>
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Medical verification, drug test screening    </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Education verification    </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Global database sanctions    </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Credit check verification    </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Criminal and court verification    </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Employment verification    </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Reference verification    </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Professional license verification    </li>   
                  </ul>
                </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
