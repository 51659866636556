import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import empBanner from '../../assets/images/empBanner.svg';
import empReliableTeam from '../../assets/images/empReliableTeam.svg';
import empConfidentiality from '../../assets/images/empConfidentiality.png';
import empTrustedEmp from '../../assets/images/empTrustedEmp.svg';
import empProfessionalVerification from '../../assets/images/empProfessionalVerification.svg';

export const EmploymentVerification = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Past employment verification</h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={empBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>

     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={empReliableTeam} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Build a reliable team with poise</h2>
               <p className="para mt-4">An employee's past employment history is a crucial indicator of their professional background. Unfortunately, instances of falsified employment records are not uncommon. To combat this, Intent Screen assists its clients in verifying various details of past employment, aiding in fraud prevention.</p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Confidentiality verification </h2>
               <p className="para mt-4">Employees are exposed to many different confidential documents throughout the job. The level of trust and loyalty needs to be verified to ensure the company’s documents and secrets are safe.  </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={empConfidentiality} alt="" />
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={empTrustedEmp} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Trusted employment verification</h2>
               <p className="para mt-4">Intent Screen provides trusted employment verification, confirming past employment details to ensure accuracy and reliability for informed hiring decisions. </p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">A professional verification help</h2>
               <p className="para mt-4">Our team of professionals is dedicated to ensuring a seamless verification process, providing expert assistance to streamline and simplify the verification journey for our clients.</p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={empProfessionalVerification} alt="" />
           </div>
         </div>
       </div>
     </section>
     <Footer />
    </div>
  )
}
