import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/ngoBanner.png';
import ngo from '../../assets/images/ngo.png';

export const NGO = () => {
    useEffect(() => {
        animate()
      }, [])

  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Industry</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Non-profit organizations</h1>
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
    
     <section id="about" className="ourVisionSec">
       <div className="container">
        <h2 className='main-title text-center'>Non-profit organizations- screening good people for a good cause</h2>
        <div className="para mt-3 text-center mb-4">Non-profit organizations have the biggest challenge of recruiting good people for a good cause. These organizations have different volunteers, permanent members, and part-time workers. Intent screen helps these non-profit organizations as a small contribution to the good cause. </div>
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={ngo} alt="" />
           </div>
           <div className="col-md-6 ">
                <div className="listContent">
                    <ul>
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Education verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Employment verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Criminal record check  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Court verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Reference verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Global database and Physical address verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Citizenship verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Identity verification  </li>   
                        <li className='para animatable slideInUp animationDelay100'><span className='listIco'></span> Social media verification  </li>   
                  </ul>
                </div>
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
