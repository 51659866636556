import React, { useEffect } from 'react'
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/translationServiceBanner.svg';
import translateDoc from '../../assets/images/translateDoc.svg';
import convertMultipleLang from '../../assets/images/convertMultipleLang.svg';

export const TranslationService = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Translation services</h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img className='remove-bg' src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={translateDoc} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Converting the document to another language</h2>
               <p className="para mt-4">
               Document translation is a whole new different field. In this important documents are converted from one language to another. This helps our clients who are native speakers of one language to understand important documents produced in another language.                 
               </p>
             </div>
           </div>
         </div>
       </div>
     </section>
 
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Translating into multiple languages</h2>
               <p className="para mt-4">
               We provide a wide range of language translation services to our clients. They can do so by just uploading the documents. We are a highly reputed platform that provides translation services to our clients by providing an accuracy of more than 90%. We have a team of experts who have mastered different languages, who translate the documents in no time and send them to you.                  </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={convertMultipleLang} alt="" />
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
