import React, { useEffect } from 'react'
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import aboutImg from '../../assets/images/aboutImg.svg';
import mainBanner from '../../assets/images/socialMediaBanner.svg';
import socialInsight from '../../assets/images/socialInsight.png';
import socialInsight2 from '../../assets/images/socialInsight2.svg';
import socialSummarize from '../../assets/images/socialSummarize.svg';

export const SocialMediaVerification = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Social media verification </h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={socialInsight} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Social media unravels important insights </h2>
               <p className="para mt-4">
               Social media is the mirror image of a person. It helps discover different information from different perspectives. Social media verification provides the best sources of unwritten and unspoken information about a potential employee.                </p>
             </div>
           </div>
         </div>
       </div>
     </section>
 
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Discover personal preferences and hidden insights </h2>
               <p className="para mt-4">
               Uncover personal preferences and glean hidden insights about candidates through our social media checks conducted by our dedicated research team.
                </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={socialInsight2} alt="" />
           </div>
         </div>
       </div>
     </section>

  
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={socialSummarize} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Summarised report</h2>
                <p className="para mt-4">
                 We provide our clients with detailed yet summarized reports for social media verification of an employee. We provide a comprehensive digital media background check. 
                </p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <Footer />
    </div>
  )
}
