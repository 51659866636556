import React, { useEffect } from 'react'
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/databseSactoonBanner.png';
import dbSanction from '../../assets/images/dbSanction.png';
import dbList from '../../assets/images/dbList.svg';

export const GlobalDB = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Global Database and Sanctions </h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img className='remove-bg' src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={dbSanction} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Global database sanctions </h2>
               <p className="para mt-4">
               Companies aiming to expand their presence internationally often face problems of hiring people from different locations and regions of the world. Their verification and screening becomes a complex task even more. An additional burden is the regulatory authorities and sanctions. We, Intent Screen, keep a hold on different global information including watch lists, sanctions, terrorist lists, etc… This information is used to provide verification services to companies for the international employee recruitment process. 
                </p>
             </div>
           </div>
         </div>
       </div>
     </section>
 
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Our list to keep you hassle-free </h2>
               <p className="para mt-4">
               We have a detailed list containing different information from government websites, officials, etc. to gather the required information. This information also includes a sanction list based on different reasons. Among these, credit defaults are the most common. We use this information to compile a comprehensive verification report. 
                </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={dbList} alt="" />
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
