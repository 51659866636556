import React, { useEffect } from 'react'
import aboutImg from '../../assets/images/aboutImg.svg';
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/licenseBanner.svg';
import licenseForgery from '../../assets/images/licenseForgery.svg';

export const LicenseVerification = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">Professional License verification </h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={licenseForgery} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Forgery is a common practice</h2>
               <p className="para mt-4">
               Employees tend to forge their certificates, a common practice. Employers always verify the professional license claimed by the employees. It is the basic proof of honesty and credibility. We at Intent Screen provide credential verification  by providing the authenticity of the claim as well as all the necessary details regarding the professional license. Some of the commonly verified professional licences are nursing, human resources, supply chain, engineers, aviation, etc…                </p>
             </div>
           </div>
         </div>
       </div>
     </section>
     <Footer />
    </div>
  )
}
