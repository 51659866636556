import React, { useEffect } from 'react'
import Logo from '../assets/images/Logo.svg';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useMediaQuery } from 'react-responsive';

export const Navbar = () => {
  const isMed = useMediaQuery({
    query: '(max-width: 992px)'
  })
  
  const openSideNav = () =>{
    console.log("opne");
    let elem =document.getElementById('industryMenu')
    if(isMed){
      elem.style.left = "0%";
    }
  }
  const closeSideNav = () =>{
    let elem =document.getElementById('industryMenu')
    if(isMed){
      elem.style.left = '-100%';
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light py-4  a1 row mx-0  navFont pr-0">
        <div className=" col-auto text-center ">
        <Link className="navbar-brand p-0" style={{marginTop: '-6px'}} to="/">
            <img className="img-fluid" id="logo" src={Logo} alt="Logo" /> 
            {/* <div className='main-title font-bold'>LOGO</div> */}
        </Link>
        </div>
        <div className="order-md-2 order-3 toggle-icon-custom">
        <button className="navbar-toggler btn-btn-toggle" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
        </button>
        </div>
        <div className="collapse navbar-collapse col  jc-end collapse-nav pr-0-lg" id="navbarNavAltMarkup">
        <div className='flex-1'>
            <div className="navbar-nav jc-center">
              <Link className="nav-item nav-link" to="/">Home </Link>
              <HashLink  className="nav-item nav-link" to="/#services">Services</HashLink>
              <div className='position-relative'>
                <a onClick={openSideNav} className="nav-item nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-expanded="false">
                  Industry
                </a>
                <ul className="dropdown-menu industryMenu" id='industryMenu' aria-labelledby="navbarDropdownMenuLink">
                  {/* <div className="closeBar" onClick={closeSideNav} ><i class="fas fa-times"></i></div> */}
                  <li>
                    <div className="row">
                      {/* <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/aerospace-defence" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span>Aerospace and Defence</div>
                        </Link>
                      </div> */}
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/healthcare" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span> Healthcare background screening </div>
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/hospitality" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span>Hospitality- improve the service experience </div>
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/IT" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span>Manufacturing and IT- screening for developing businesses and technologies </div>
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/NGO" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span> Non-profit organizations- screening good people for a good cause</div>
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/retail" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span> Retail- finding the best employee fit </div>
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/staffing" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span> Staffing- help provide a strong and reliable human resource  </div>
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/tenant" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span> Tenant verification- help protect your property </div>
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link onClick={closeSideNav} to="/industry/transportation" className="nav-link" > 
                          <div className="downItem"><span className='listIco'></span> Transportation- protection on the road </div>
                        </Link>
                      </div>
                     
                    </div>
                  </li>
                </ul>
              </div>
              {/* <HashLink className="nav-item nav-link" to="/resource">Resource</HashLink> */}
              <HashLink className="nav-item nav-link" to="/#about">About</HashLink>
            </div>
        </div>
        <Link to="/contact" className="sigupBtn">Contact Us</Link>
        </div>
    </nav>
  )
}
