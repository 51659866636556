import React, { useEffect } from 'react'
import { Navbar } from '../../component/navbar';
import { Footer } from '../../component/footer';
import { Link } from 'react-router-dom';
import animate from '../../assets/js/animate';
import mainBanner from '../../assets/images/idBanner.svg';
import smartAndSimple from '../../assets/images/smartAndSimple.svg';
import idReliable from '../../assets/images/idReliable.svg';

export const IDVerification = () => {
    useEffect(() => {
        animate()
      }, [])
  return (
    <div className="servicePage">
            <header className="header" id="header">
       <section className="container ">
            <Navbar />
       </section>
       <section className='headerSection'>
         <div className="container">
           <div className="row ai-center">
             <div className="col-lg-6 col1" >
               <div className='animatable slideInUp animationDelay100' >
                 <span className="biggestKnowledge">Background Checks</span>
               </div>
               <div className='animatable slideInUp animationDelay300' >
                 <h1 className="main-title">ID verification</h1>
               </div>
               <div className="mt-5 headerBtn animatable slideInUp animationDelay700" >
                 <Link to="/contact" className="getStatedBtn">Get a Demo</Link>
                 {/* <button className="buyCoinBtn ml-3">Whitepaper</button> */}
               </div>
             </div>
             <div className="col-lg-6 col-12 col2">
               <img className='remove-bg' src={mainBanner} alt="blockchain" />
             </div>
           </div>
         </div>
       </section>
     </header>
   
    
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={smartAndSimple} alt="" />
           </div>
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Smart and simple verification for smarter hiring </h2>
               <p className="para mt-4">
               ID verification is the cornerstone of vendor and employee authentication at Intent Screen. Utilizing modern technology and a proficient team, we ensure precise and reliable verification services. By meticulously examining identification documents, we validate individuals' authenticity, fostering trust and security within organizations
                </p>
             </div>
           </div>
         </div>
       </div>
     </section>
 
     <section id="about" className="ourVisionSec">
       <div className="container">
         <div className="row ai-center jc-center">
           <div className="col-md-6 animatable zoomIn animationDelay100" >
             <div className="col2">
               <h2 className="main-title">Reliable and Accurate results</h2>
                <p className="para mt-4">
                We provide ID verification internationally after screening different databases online to provide reliable and accurate verification. 
                </p>
             </div>
           </div>
           <div className="col-md-6 col-10 jc-center col1 animatable zoomIn animationDelay100">
             <img className="w-100 hoverTranslateY" src={idReliable} alt="" />
           </div>
         </div>
       </div>
     </section>

     <Footer />
    </div>
  )
}
